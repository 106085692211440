import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "related-actions"
    }}>{`Related Actions`}</h1>
    <p>{`The Related Actions menu may also be referred to as the Actions menu. Related Actions menus may have
multiple levels:`}</p>
    <ul>
      <li parentName="ul">{`The first level, or the primary menu, consists of categories. You can use nouns or verbs to name
these categories.`}</li>
      <li parentName="ul">{`The second level, or secondary menu, contains more specific menu items. Use verbs when naming menu
items whenever possible, and try to make them as specific as possible.`}</li>
    </ul>
    <p>{`Use title case for the menu title and all menu items.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Benefits>Change Benefits`}</li>
        <li parentName="ul">{`Benefits>View Dependents`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Benefits>Change`}</li>
        <li parentName="ul">{`Manage Benefits>Benefit Change`}</li>
      </ul>
    </Dont>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      